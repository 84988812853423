import React from 'react';
import useMediaQuery from '@hook/useMediaQuery';
import Show from '@common/Show';
import Image from 'next/image';
import cx from 'classnames';
import Dialog from '@common_dialog';
import Typography from '@common_typography';
import { getCmsBlocks } from '@core_modules/theme/services/graphql';
import { useRouter } from 'next/router';
import CmsRenderer from '@core_modules/cms/components/cms-renderer';

const CustomerService = (props) => {
    const { t, storeConfig } = props;
    const { isMobile } = useMediaQuery();
    const [openModal, setOpenModal] = React.useState(false);
    const route = useRouter();

    const { pathname } = route;
    const listNoShow = ['/checkout', '/otp'];

    const { data } = getCmsBlocks({
        identifiers: 'oppo_modal_cs_support',
    });
    const content = data?.cmsBlocks?.items[0]?.content;

    if (listNoShow.find((item) => item === pathname)) {
        return <></>;
    }

    return (
        <>
            <Dialog open={openModal} variant="plain">
                <div
                    className={cx(
                        'bg-neutral-white',
                        'rounded-[24px]',
                        'mobile:py-[20px] mobile:px-[16px]',
                        'tablet:py-[24px] tablet:px-[24px]',
                        'desktop:py-[24px] desktop:px-[24px]',
                        'desktop:w-[540px]',
                        'mx-5',
                    )}
                >
                    <div className={cx('dialog-title', 'flex flex-row', 'justify-between')}>
                        <div className={cx('mobile:mb-[20px] tablet:mb-[24px] desktop:mb-[24px]')}>
                            <Typography className={cx('mobile:!text-2xl tablet:!text-2xl desktop:!text-2xl')}>{t('common:label:support')}</Typography>
                        </div>
                        <div
                            className={cx('bg-neutral-black', 'rounded-[50px]', 'cursor-pointer', 'w-[30px] h-[30px]')}
                            aria-hidden="true"
                            onClick={() => setOpenModal(false)}
                        >
                            <div className={cx('w-[30px] h-[30px]')}>
                                <Image src="/assets/img/icon/close-circle.svg" alt="close sircle customer service" width={30} height={30} />
                            </div>
                        </div>
                    </div>
                    <div className={cx('cms-customer-service-content')}>
                        <Show when={content}>
                            <CmsRenderer content={content} storeConfig={storeConfig} />
                        </Show>
                    </div>
                </div>
            </Dialog>

            <div
                className={cx(
                    'customer-service-wrapper fixed z-50',
                    'mobile:right-[25px] mobile:bottom-[80px]',
                    'tablet:right-[50px] tablet:bottom-[100px]',
                    'desktop:right-[50px] desktop:bottom-[100px]',
                    'p-[12px]',
                    'rounded-[50px]',
                    'border-[2px] border-oppo_green',
                    'bg-neutral-white',
                    'shadow-[0_0_0_4px] shadow-oppo_green-200',
                    'cursor-pointer',
                )}
                aria-hidden="true"
                onClick={() => setOpenModal(true)}
            >
                <Show when={isMobile}>
                    <Image src="/assets/img/icon/cs.svg" alt="customer-service" quality={80} width={24} height={24} />
                </Show>
                <Show when={!isMobile}>
                    <Image src="/assets/img/icon/cs.svg" alt="customer-service" quality={80} width={36} height={36} />
                </Show>
            </div>
        </>
    );
};

export default CustomerService;
